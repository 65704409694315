import { htmlDecode } from '@shein/common-function'

export const timeZone = () => {
  // 时区
  const date = new Date()
  const timeZone = date.getTimezoneOffset() / 60
  return `GMT${timeZone > 0 ? '-' : '+'}${Math.abs(timeZone)}`
}

export const htmlDecodeUtil = ({ text, isHtml = true }) => {
  return htmlDecode({ text, isHtml })
}

const { PUBLIC_CDN, GB_cssRight } = gbCommonInfo
const prefix = PUBLIC_CDN + '/she_dist/images/user/renew_dialog'
// todo 名字替换
const IMAGES = {
  giftImage: GB_cssRight ? 'renew-gift-ar-586bb38069' : 'renew-gift-2cb910ee67',
  bgTopImage: GB_cssRight
    ? 'renew-bg-top-ar-634720435e'
    : 'renew-bg-top-8060b2bdfc',
  closeImage: 'renew-close-7c4ceb6101',
  renewMainBg: 'renew-main-bg-28170d7f6f',
  renewMainBgCoupon: 'renew-main-bg-coupon-82b911f821'
}

const RENEW_IMG = {}
for (const [key, value] of Object.entries(IMAGES)) {
  RENEW_IMG[key] = `${prefix}/${value}.png`
}

// 新续费弹窗
const RENEW_IMG_V2 = {
  flashIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/11/12/cb/1731411607c8bc6550b3c587f3939e2088ff755ab9.webp',
  triangle: 'https://img.ltwebstatic.com/images3_ccc/2024/11/07/6a/173096393042bf4b828bd29891f8033c74bb36a5e5.webp',
  promotionBg: 'https://img.ltwebstatic.com/images3_ccc/2024/11/06/39/173089293484e46dff311bbe4852a300615275fa57.webp',
  dash: 'https://img.ltwebstatic.com/images3_ccc/2024/11/15/1f/17316525389556de731076948dede4fa5899ef94f1.webp',
  dashes: 'https://img.ltwebstatic.com/images3_ccc/2024/11/15/a2/17316534512b3579eec6e17b9efe45bd24ffdfe932.webp'
}

export { RENEW_IMG, RENEW_IMG_V2 }
